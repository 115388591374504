// Other

(function ($) {
    $(document).ready(function () {

        var $shareList = $(".share-list");
        var shareSlideUp = 100;
        var shareSildeDown = 200;

        /**
         * Remove all share list active
         * @param e Event
         */
        function removeAllActiveShare(e) {
            if (e === true ||  (!$(e.target).closest(".share-list").length && !$(e.target).closest(".share-link").length)) {
                $shareList.removeClass('active').slideUp(shareSlideUp);
            }
        }



        /**
         * Enable or Disabled Share List
         * And disabled list when on click out of share-link
         */
        $('.share-link').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var shareItem = $(this).next();

            if (shareItem.hasClass('active')) {
                shareItem.removeClass("active").slideUp(shareSildeDown);
                $("body").off('click', removeAllActiveShare);
            }
            else {
                $shareList.removeClass('active').slideUp(shareSlideUp);
                shareItem.addClass("active").slideDown(shareSildeDown);
                $("body").on('click', removeAllActiveShare);
            }

        });

        $('.share-close').on('click', function(e){
            e.preventDefault();
            removeAllActiveShare(true)
        });


        $("a.footer-menu-button").on("click", function (e) {
            var menuWindow = $(this).next();
            e.stopPropagation();
            var isActive = menuWindow.hasClass('active');
            $.when(removeAllFooterMenu()).done(function () {
                if (!isActive) {
                    menuWindow.slideToggle(200);
                    menuWindow.toggleClass('active');
                    $("body").on('click', removeAllFooterMenu);
                }
            });
        });

        function removeAllFooterMenu(e) {
            if (e == null || !$(e.target).closest(".favorite-hist-menu.active").length && !$(e.target).closest(".favorite-hist-menu.active").length) {
                $(".favorite-hist-menu.active").slideToggle(100).removeClass('active');
                $("body").off('click', removeAllFooterMenu);
            }
        }


        $(".favorite-hist-menu").each(function () {
            var current = $(this);

            var actions = current.find(".action");
            $(actions[0]).on("click", function () {
                var list = $(current).find("li");
                //GO UP
                var tmp = {i: 0, el: null};
                list.each(function (i, el) {
                    if ($(el).hasClass("active")) {
                        tmp.i = i;
                        tmp.el = $(el);
                    }
                });
                if (tmp.i === 0)
                    $(list[list.length - 1]).toggleClass("active");
                else
                    tmp.el.prev().toggleClass("active");
                tmp.el.toggleClass("active");
            });
            $(actions[1]).on("click", function () {
                var list = $(current).find("li");
                //GO DOWN
                var tmp = {i: 0, el: null};
                list.each(function (i, el) {
                    if ($(el).hasClass("active")) {
                        tmp.i = i;
                        tmp.el = $(el);
                    }
                });
                if (tmp.i === list.length - 1)
                    $(list[0]).toggleClass("active");
                else
                    tmp.el.next().toggleClass("active");
                tmp.el.toggleClass("active");
            });
        });

        $(".fav-item").on("click", function () {
            var menu = $(this).parent().parent().find(".fav-added-menu");
            var state = menu.is(":visible");
            $(".fav-added-menu").hide();
            if (!state) {
                menu.toggle();
            }
        });

        $(".fav-main-legend").on("click", function () {
            $(this).parent().parent().toggle();
        });

        // Tab program load place
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            var $target = $(e.target),
                programId = $target.data("program"),
                placeId = $target.data("place"),
                planContainer = $($target.attr('href'));
            programSetPlace(programId, placeId, planContainer);
        });

        var currentActivePlan = {
            container: null,
            items: null,
            xhr: null
        };

        var PlanItemsList = {};

        function programSetPlace(programId, placeId, planContainer) {
            if (currentActivePlan.xhr !== null && currentActivePlan.xhr.readyState !== 4) {
                currentActivePlan.xhr.abort();
            }
            var id = planContainer.attr("id");
            planContainer.find('i').remove();
            if (typeof PlanItemsList[id] === "undefined") {
                currentActivePlan.xhr = $.ajax({
                    url: '/programmes-immobiliers/ajax/program/' + programId + '/place/' + placeId,
                    type: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    currentActivePlan.container = planContainer;
                    currentActivePlan.items = data.lots;
                    PlanItemsList[id] = currentActivePlan.items;
                    renderPlacePointer();
                }).fail(function () {
                }).always(function () {
                });
            }
            else {
                currentActivePlan.container = planContainer;
                currentActivePlan.items = PlanItemsList[id];
                renderPlacePointer();
            }

        }

        function renderPlacePointer() {
            if (currentActivePlan.container !== null) {

                $.each(currentActivePlan.items, function (index, item) {
                    addPlacePointer(currentActivePlan.container, item);
                });
                $('[data-toggle="tooltip"]').tooltip();
            }
        }

        function addPlacePointer(container, item) {
            var img = $(container.find('img')[0]);
            // container.find("i").remove();
            var targetWidth = parseFloat(img.width());
            var targetHeight = parseFloat(img.height());

            if (item.placeX > 0 && item.placeY > 0) {

                var x = targetWidth * parseFloat(item.placeX) / 100;
                var y = targetHeight * parseFloat(item.placeY) / 100;
                if (typeof item.url !== 'undefined') {
                    var marker = '<a href="' + item.url + '">' +
                        '<span class="add-tooltip markerLot ' + (item.state === 1 ? 'active' : '') + '" ' +
                        'data-slug="' + item.slug + '" ' +
                        'data-id="' + $(this).attr("data-id") + '" ' +
                        'style="top:' + y + 'px;left:' + x + 'px; position: absolute;">' +
                        '<span class="icon" data-toggle="tooltip" ' +
                        'data-original-title="Lot n° ' + item.number + ' ( ' + item.livingSpace + 'm2 )"></span>' +
                        '</span>' +
                        '</a>';
                } else {
                    var marker = '<i class="add-tooltip markerLot ' + (item.state === 1 ? 'active' : '') + '" ' +
                        'data-slug="' + item.slug + '" ' +
                        'data-id="' + $(this).attr("data-id") + '" ' +
                        'style="top:' + y + 'px;left:' + x + 'px; position: absolute;">' +
                        '<span class="icon" data-toggle="tooltip" ' +
                        'data-original-title="Lot n° ' + item.number + ' ( ' + item.livingSpace + 'm2 )"></span>' +
                        '</i>';
                }
                container.append(marker);
            }
        }

        // Run first Active tab
        var itemActive = $($(".nav-tabs > li:nth-child(1) > a:nth-child(1)")[0]);

        if (itemActive.length !== 0) {
            $(itemActive.attr('href') + " img").one("load", function () {
                var itemActiveProgramId = itemActive.data("program"),
                    itemActivePlaceId = itemActive.data("place"),
                    itemActivePlanContainer = $(itemActive.attr('href'));
                programSetPlace(itemActiveProgramId, itemActivePlaceId, itemActivePlanContainer);
            }).each(function () {
                if (this.complete) $(this).trigger('load');
            });

        }

        $(window).on('resize', function () {
            renderPlacePointer();
        });

        $('.markerLot.active').on('click', function () {

        });


        if ($('#consult-choice-emplacement').length > 0) {
            var hidWidth;
            var scrollBarWidths = 40;

            var widthOfList = function () {
                var itemsWidth = 0;
                $('.list li').each(function () {
                    var itemWidth = $(this).outerWidth();
                    itemsWidth += itemWidth;
                });
                return itemsWidth;
            };

            var widthOfHidden = function () {
                return (($('.wrapper').outerWidth()) - widthOfList() - getLeftPosi()) - scrollBarWidths;
            };

            var getLeftPosi = function () {
                return $('.list').position().left;
            };

            var reAdjust = function () {
                if (($('.wrapper').outerWidth()) < widthOfList()) {
                    $('.scroller-right').show();
                }
                else {
                    $('.scroller-right').hide();
                }

                if (getLeftPosi() < 0) {
                    $('.scroller-left').show();
                }
                else {
                    $('.item').animate({left: "-=" + getLeftPosi() + "px"}, 'slow');
                    $('.scroller-left').hide();
                }
            }

            reAdjust();

            $(window).on('resize', function (e) {
                reAdjust();
            });

            $('.scroller-right').click(function () {

                $('.scroller-left').fadeIn('slow');
                $('.scroller-right').fadeOut('slow');

                $('.list').animate({left: "+=" + widthOfHidden() + "px"}, 'slow', function () {

                });
            });

            $('.scroller-left').click(function () {

                $('.scroller-right').fadeIn('slow');
                $('.scroller-left').fadeOut('slow');

                $('.list').animate({left: "-=" + getLeftPosi() + "px"}, 'slow', function () {

                });
            });
        }
    });

    // Defilement ancre
    // Pour tous les liens commençant par #.
    $("a[href^='#']").on('click', function (e) {
        // Exception
        if ($(e.target).attr('role') !== "tab") {
            if ($(this).attr("href") === '#') {
                return true;
            }

            var
                yPos,
                yInitPos,
                target = ($($(this).attr("href") + ":first")),
                menuHeight = $("nav.navbar").height();

            // On annule le comportement initial au cas ou la base soit différente de la page courante.
            e.preventDefault();

            yInitPos = $(window).scrollTop();

            // On ajoute le hash dans l'url.
            window.location.hash = $(this).attr("href");

            // Comme il est possible que l'ajout du hash perturbe le défilement, on va forcer le scrollTop à son endroit inital.
            $(window).scrollTop(yInitPos);

            // Sinon on cherche l'ancre dans le name d'un a.
            if (target.length === 0) {
                target = ($("a[name=" + $(this).attr("href").replace(/#/gi, "") + "]:first"));
            }

            // Si on a trouvé un name ou un id, on défile.
            if (target.length === 1) {
                yPos = target.offset().top; // Position de l'ancre.

                // On anime le défilement jusqu'à l'ancre.
                $('html,body').animate({scrollTop: yPos - menuHeight - 20}, 1000); // On décale de 40 pixels l'affichage pour ne pas coller le bord haut de l'affichage du navigateur et on défile en 1 seconde jusqu'à l'ancre.
            }
            return false;
        }
    });

    var paddingBottomInitial = $('.main-content > div:nth-last-child(1)').css('padding-bottom');

    function resizeMainContentWhenIsSlowCompleted() {
        var $footerList = $(".footer-list");
        var $mainContent = $('.main-content');
        var className = 'footer-list-stick';
        var bodyHeight = $mainContent.position().top + $mainContent.outerHeight(true) + $footerList.outerHeight(true);
        var windowsHeight = $(window).height();
        if (bodyHeight < windowsHeight) {
            $footerList.addClass(className);
        } else {
            $footerList.removeClass(className);
        }
    }

    $(window).on('resize', resizeMainContentWhenIsSlowCompleted);

    $("img").one("load", function() {
        resizeMainContentWhenIsSlowCompleted();
    }).each(function() {
        if (this.complete) $(this).trigger('load');
    });

    resizeMainContentWhenIsSlowCompleted();

    /**
     * Magnific popup gallery
     */
    if ($('#consult-photos').length > 0){
        $('#consult-photos .consult-carousel-photo-item').magnificPopup({

            type: 'image',
            gallery: {
                enabled:true
            },
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    return item.el.attr("alt");
                }
            },
            zoom: {
                enabled: true
            }
        });
    }

    // Witness extesion
    var $witnessExtension = $("#witness-extension");

    if ($witnessExtension.length > 0) {
        var $witnessBtn = $("#witness-btn");
        $witnessExtension.hide().removeClass("hidden");
        $witnessBtn.addClass("clickable");
        $witnessBtn.on('click', function(){
            $witnessExtension.stop().slideToggle(400);
        });
    }


})(jQuery);

/**
 * @param el jQuery
 */
function placeLoaderOnElement(el) {
    var container = $('<div/>').addClass('loader-container');
    var loader = $('<div/>').addClass('loader');

    loader.appendTo(container);
    container.appendTo(el);
}

/**
 * @param el jQuery
 */
function removeLoaderOnElement(el) {
    el.find('.loader, .loader-container').remove();
}
